/* Chatbox */

.chat_body {
    margin: 0;
    padding: 0;
    font-family: "Lato", sans-serif;
    background-color: #f6f7f9;
}

.chat_h1 {
    margin: 0;
    font-size: 16px;
    line-height: 1;
}

.chat_button {
    color: inherit;
    background-color: transparent;
    border: 0;
    outline: 0 !important;
    cursor: pointer;
}

    .chat_button.chatbox_open {
        position: fixed;
        bottom: 0;
        right: 0;
        width: 52px;
        height: 52px;
        color: #fff;
        background-color: #0360a5;
        background-position: center center;
        background-repeat: no-repeat;
        box-shadow: 12px 15px 20px 0 rgba(46, 61, 73, 0.15);
        border: 0;
        border-radius: 50%;
        cursor: pointer;
        margin: 16px;
    }

    .chat_button.chatbox_close {
        position: fixed;
        bottom: 0;
        right: 0;
        width: 52px;
        height: 52px;
        color: #fff;
        background-color: #0360a5;
        background-position: center center;
        background-repeat: no-repeat;
        box-shadow: 12px 15px 20px 0 rgba(46, 61, 73, 0.15);
        border: 0;
        border-radius: 50%;
        cursor: pointer;
        margin: 16px;
    }

.chat_textarea {
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    height: calc(16px + 16px / 2);
    padding: 0 calc(16px / 2);
    font-family: inherit;
    font-size: 16px;
    line-height: calc(16px + 16px / 2);
    color: #888;
    background-color: white;
    border: 0;
    outline: 0 !important;
    resize: none;
    overflow: hidden;
}

    .chat_textarea::placeholder {
        color: #888;
    }

.chatbox_popup {
    display: flex;
    position: absolute;
    box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
    flex-direction: column;
    bottom: calc(2 * 16px + 52px);
    right: 16px;
    width: 460px;
    height: auto;
    background-color: #fff;
    border-radius: 16px;
}

.chatbox_popup__header {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    padding: 16px;
    color: #fff;
    background-color: #0360a5;
    align-items: center;
    justify-content: space-around;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}

.chatbox_popup__avatar {
    margin-top: -4px;
    background-color: #0360a5;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
}

.chatbox_popup__main {
    box-sizing: border-box;
    width: 100%;
    padding: calc(2 * 16px) 16px;
    line-height: calc(16px + 16px / 2);
    color: #888;
    text-align: center;
    height: 200px;
}

.chatbox_popup__footer {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    padding: 16px;
    border-top: 1px solid #ddd;
    align-items: center;
    justify-content: space-around;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
}

.chatbox_panel {
    display: flex;
    position: absolute;
    box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
    flex-direction: column;
    top: 0;
    right: 0;
    bottom: 0;
    width: 460px;
    background-color: #fff;
    border-radius: 16px;
}

.chatbox_panel__header {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    padding: 16px;
    color: #fff;
    background-color: #0360a5;
    align-items: center;
    justify-content: space-around;
    flex: 0 0 auto;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}

.chatbox_panel__main {
    box-sizing: border-box;
    width: 100%;
    padding: calc(2 * 16px) 16px;
    line-height: calc(16px + 16px / 2);
    color: #888;
    text-align: center;
    flex: 1 1 auto;
}

.chatbox_panel__footer {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    padding: 16px;
    border-top: 1px solid #ddd;
    align-items: center;
    justify-content: space-around;
    flex: 0 0 auto;
}
